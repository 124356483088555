@import '../settings';
@import '../Layout/mixin/flex_box';

.message {
    padding: $padding-micro;
    text-align: left;
    margin-bottom: 3px;
    background-color: $bg-default;
    border: 1px solid $border-default;
    border-radius: $border-radius-default $border-radius-default
        $border-radius-default 0;
    overflow: hidden;

    .message_own & {
        background-color: darken($bg-default, 2%);
        border-bottom-right-radius: 0;
    }
}

.message_own {
    position: relative;
    padding-left: 20px;
}

.messageContent {
    flex-grow: 1;
}

.actions {
    float: right;
}

.wrapper {
    position: relative;
    padding-right: 20px;
}

.marker {
    position: absolute;
    left: 0;
    bottom: 18px;
    width: 20px;
    height: 20px;
    z-index: 201;
    border: 1px solid $border-default;
    background-color: $bg-default;

    transform: translate(-45%, 100%) rotate(45deg);
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%);

    .message_own & {
        left: auto;
        right: 0;
        transform: translate(45%, 50%) rotate(45deg);
        clip-path: polygon(100% 100%, 100% 0%, 0% 0%);
    }
}

.author {
    font-size: $font-size-small;
    color: $font-color-medium;
    padding-right: $padding-small;
    padding-bottom: 4px;
}

.meta {
    padding-bottom: 15px;
    padding-right: $padding-small;
    font-size: 0.8rem;
    color: $font-color-light;

    .message_own & {
        text-align: right;
    }
}
